<template>
  <div class="page_bg">
    <div class="bg_top_linear-gradient vip_linear-gradient" style="height: auto;">
      <!-- 顶部返回 -->
      <div class="back_top_name flex-r-sb-c">
        <van-icon name="arrow-left" @click="backPath" />
        <div class="top_name">
          <van-image width="2.84rem" height="0.64rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/vip/vip_title_rule.png"
          />
        </div>
        <div class="top_icon" />
      </div>
      <van-image class="vip_avater" width="9.47rem" height="26.91rem"
                 src="https://hxxxkj-oss-cdn.xiaoyi120.com/sysx-xcx/vip/vip_rule7-1.png"
      />
      <van-image class="vip_avater" width="9.47rem" height="6.72rem"
                 src="https://hxxxkj-oss-cdn.xiaoyi120.com/sysx-xcx/vip/vip_rule8.png"
      />
      <van-image class="vip_avater" width="9.47rem" height="18.56rem"
                 src="https://hxxxkj-oss-cdn.xiaoyi120.com/sysx-xcx/vip/vip_rule9.png"
      />
    </div>
  </div>
</template>

<script>
import './vipDel.scss'
export default {
  name: 'VipAuth',
  data() {
    return {

    }
  },
  computed: {

  },
  methods: {
    backPath() { window.history.back() }

  }
}
</script>

<style lang="scss" scoped>
</style>
